export default {
    account_restored: "Compte restauré",
    restore_account: "Restaurer le compte",
    authorization_platform: "Se connecter ou créer un compte",
    on_your: "Sur votre",
    email: "adresse e-mail",
    code_has_been_sent: "un code de confirmation a été envoyé",
    enter_code: "Entrez le code",
    code_is_valid: "Le code est valide",
    minutes: "minutes",
    request_new_code: "Vous pourrez demander un nouveau code dans",
    sec: "secondes",
    send_again: "Renvoyer le code",
    back: "Retour",
    next: "Suivant",
    enter_password: "Entrez le mot de passe",
    repeat_password: "Répétez le mot de passe",
    forgot_password: "Mot de passe oublié ?",
    restore_access: "Restaurer l'accès",
    enter_username: "Entrez l'adresse e-mail ou le numéro de téléphone",
    enter_email: "Entrez l'adresse e-mail",
    create_new_password: "Créez un nouveau mot de passe",
    select_language: "Sélectionnez la langue",
    select_currency: "Sélectionnez la devise",
    language: "Langue",
    currency: "Devise",
    or: "ou",
    enter: "Se connecter",
    close: "Fermer",
    fields_filled: "Champs remplis !",
    fill_title: "Remplissez les champs",
    fill_descr: "Pour finaliser l'inscription, veuillez remplir les champs",
    welcome: "Bienvenue !",
    check_locale: " Veuillez confirmer si nous avons détecté correctement votre langue :",
    all_ok: "Oui, tout est correct",
    no_change_language: "Non, changer la langue",
    set_password: "Définir le mot de passe",
    set_password_description: "Créez un mot de passe",
    understand_thanks: "Compris, merci !",
    prerecording: "Pré-enregistrement",
    prerecording_success: "Vous avez été ajouté à la liste de pré-enregistrement",
    congratulations: "Félicitations !",
    back_to_menu: "Retour au <b>Menu</b>",
    //
    partners_program: "Programme partenaire",
    become_a_partner: "Devenir partenaire",
    products: "Mes produits",
    certificates: "Certificats",
    knowledge: "Base de connaissances",
    practices: "Pratiques complétées",
    dictionary: "Dictionnaire",
    calendar: "Calendrier des événements",
    news: "Actualités",
    history: "Historique des achats",
    localization: "Langue",
    settings: "Paramètres",
    exit: "Déconnexion",
    projects: "Mes projets",
    "expert.profile.expert_organization_cabinet": "Tableau de bord de l'organisateur",
    "expert.profile.expert_producer_cabinet": "Tableau de bord du producteur",
    "expert.profile.expert_cabinet": "Tableau de bord de l'expert",
    "expert.profile.student_cabinet": "Tableau de bord de l'étudiant",
    "expert.profile.create_cabinet": "Créer un tableau de bord",
    subaccounts: "Sous-comptes",
    school: "Ouvrir votre école",
    dashboard: "Tableau de bord",
    student_dashboard: "Ma formation",
    logout: "Se déconnecter",
    auth_type_title: "Bienvenue !",
    auth_type_btn_1: "Définir le mot de passe",
    auth_type_btn_2: "Conserver la méthode d'authentification actuelle",
    subscribe: "S'abonner"
}
