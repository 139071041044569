export default {
    account_restored: "账户已恢复",
    restore_account: "恢复账户",
    authorization_platform: "登录或创建账户",
    on_your: "在您的",
    email: "电子邮件地址",
    code_has_been_sent: "已发送验证码",
    enter_code: "输入验证码",
    code_is_valid: "验证码有效",
    minutes: "分钟",
    request_new_code: "您可以在以下时间后重新请求验证码",
    sec: "秒",
    send_again: "重新发送验证码",
    back: "返回",
    next: "下一步",
    enter_password: "输入密码",
    repeat_password: "重复输入密码",
    forgot_password: "忘记密码？",
    restore_access: "恢复访问",
    enter_username: "输入电子邮件地址或电话",
    enter_email: "输入电子邮件地址",
    create_new_password: "创建新密码",
    select_language: "选择语言",
    select_currency: "选择货币",
    language: "语言",
    currency: "货币",
    or: "或",
    enter: "登录",
    close: "关闭",
    fields_filled: "字段已填写！",
    fill_title: "填写字段",
    fill_descr: "为了完成注册，您需要填写字段",
    welcome: "欢迎！",
    check_locale: " 请问我们正确识别了您的语言吗：",
    all_ok: "是的，一切正确",
    no_change_language: "不，切换语言",
    set_password: "设置密码",
    set_password_description: "创建密码",
    understand_thanks: "明白，谢谢！",
    prerecording: "提前录制",
    prerecording_success: "您已被添加到提前录制列表中",
    congratulations: "恭喜！",
    back_to_menu: "返回<b>菜单</b>",
    //
    partners_program: "合作伙伴计划",
    become_a_partner: "成为合作伙伴",
    products: "我的产品",
    certificates: "证书",
    knowledge: "知识库",
    practices: "完成的实践",
    dictionary: "词典",
    calendar: "活动日历",
    news: "新闻",
    history: "购买历史",
    localization: "语言",
    settings: "设置",
    exit: "退出",
    projects: "我的项目",
    "expert.profile.expert_organization_cabinet": "组织者面板",
    "expert.profile.expert_producer_cabinet": "制片人面板",
    "expert.profile.expert_cabinet": "专家面板",
    "expert.profile.student_cabinet": "学生面板",
    "expert.profile.create_cabinet": "创建面板",
    subaccounts: "子账户",
    school: "创建自己的学校",
    dashboard: "仪表板",
    student_dashboard: "我的学习",
    logout: "退出账户",
    auth_type_title: "欢迎！",
    auth_type_btn_1: "设置密码",
    auth_type_btn_2: "保留当前授权方式",
    subscribe: "订阅"
}
