export default {
    account_restored: "Konto wiederhergestellt",
    restore_account: "Konto wiederherstellen",
    authorization_platform: "Einloggen oder ein Konto erstellen",
    on_your: "Auf Ihre",
    email: "E-Mail-Adresse",
    code_has_been_sent: "wurde ein Bestätigungscode gesendet",
    enter_code: "Geben Sie den Code ein",
    code_is_valid: "Der Code ist gültig",
    minutes: "Minuten",
    request_new_code: "Sie können einen neuen Code in",
    sec: "Sekunden anfordern",
    send_again: "Code erneut senden",
    back: "Zurück",
    next: "Weiter",
    enter_password: "Geben Sie das Passwort ein",
    repeat_password: "Passwort wiederholen",
    forgot_password: "Passwort vergessen?",
    restore_access: "Zugang wiederherstellen",
    enter_username: "Geben Sie die E-Mail-Adresse oder Telefonnummer ein",
    enter_email: "Geben Sie die E-Mail-Adresse ein",
    create_new_password: "Erstellen Sie ein neues Passwort",
    select_language: "Sprache auswählen",
    select_currency: "Währung auswählen",
    language: "Sprache",
    currency: "Währung",
    or: "oder",
    enter: "Einloggen",
    close: "Schließen",
    fields_filled: "Felder ausgefüllt!",
    fill_title: "Füllen Sie die Felder aus",
    fill_descr: "Um die Registrierung abzuschließen, müssen Sie die Felder ausfüllen",
    welcome: "Willkommen!",
    check_locale: " Bitte bestätigen Sie, ob wir Ihre Sprache korrekt erkannt haben:",
    all_ok: "Ja, alles korrekt",
    no_change_language: "Nein, Sprache ändern",
    set_password: "Passwort festlegen",
    set_password_description: "Erstellen Sie ein Passwort",
    understand_thanks: "Verstanden, danke!",
    prerecording: "Vorabregistrierung",
    prerecording_success: "Sie wurden in die Liste der Vorabregistrierungen aufgenommen",
    congratulations: "Herzlichen Glückwunsch!",
    back_to_menu: "Zurück zum <b>Menü</b>",
    //
    partners_program: "Partnerprogramm",
    become_a_partner: "Partner werden",
    products: "Meine Produkte",
    certificates: "Zertifikate",
    knowledge: "Wissensdatenbank",
    practices: "Abgeschlossene Übungen",
    dictionary: "Wörterbuch",
    calendar: "Veranstaltungskalender",
    news: "Neuigkeiten",
    history: "Kaufhistorie",
    localization: "Sprache",
    settings: "Einstellungen",
    exit: "Abmelden",
    projects: "Meine Projekte",
    "expert.profile.expert_organization_cabinet": "Organisator-Dashboard",
    "expert.profile.expert_producer_cabinet": "Produzenten-Dashboard",
    "expert.profile.expert_cabinet": "Experten-Dashboard",
    "expert.profile.student_cabinet": "Studenten-Dashboard",
    "expert.profile.create_cabinet": "Dashboard erstellen",
    subaccounts: "Unterkonten",
    school: "Eröffnen Sie Ihre eigene Schule",
    dashboard: "Dashboard",
    student_dashboard: "Mein Lernen",
    logout: "Vom Konto abmelden",
    auth_type_title: "Willkommen!",
    auth_type_btn_1: "Passwort festlegen",
    auth_type_btn_2: "Aktuelle Authentifizierungsmethode beibehalten",
    subscribe: "Abonnieren"
}
