export default {
    account_restored: "Účet obnoven",
    restore_account: "Obnovit účet",
    authorization_platform: "Přihlášení nebo registrace",
    on_your: "Na vaši",
    email: "e-mailovou adresu",
    code_has_been_sent: "potvrzovací kód byl odeslán",
    enter_code: "Zadejte kód",
    code_is_valid: "Kód je platný",
    minutes: "minuty",
    request_new_code: "Nový kód můžete požádat za",
    sec: "sekundy",
    send_again: "Znovu odeslat kód",
    back: "Zpět",
    next: "Další",
    enter_password: "Zadejte heslo",
    repeat_password: "Zopakujte heslo",
    forgot_password: "Zapomněli jste heslo?",
    restore_access: "Obnovit přístup",
    enter_username: "Zadejte svou e-mailovou adresu nebo telefon",
    enter_email: "Zadejte svou e-mailovou adresu",
    create_new_password: "Vytvořit nové heslo",
    select_language: "Vyberte jazyk",
    select_currency: "Vyberte měnu",
    language: "Jazyk",
    currency: "Měna",
    or: "nebo",
    enter: "Zadejte",
    close: "Zavřít",
    fields_filled: "Pole jsou vyplněna!",
    fill_title: "Vyplňte pole.",
    fill_descr: "Pro dokončení registrace musíte vyplnit pole",
    welcome: "Vítejte!",
    check_locale: "Řekněte mi, zda jsme správně identifikovali váš jazyk:",
    all_ok: "Ano, správně",
    no_change_language: "Ne, změnit jazyk",
    set_password: "Nastavení hesla",
    set_password_description: "Zvolte heslo",
    understand_thanks: "Rozumím, díky!",
    prerecording: "Předregistrace",
    prerecording_success: "Byli jste úspěšně přidáni na seznam předregistrace",
    congratulations: "Gratulujeme!",
    back_to_menu: "Zpět do <b>menu</b>",
    //
    partners_program: "Partnerský program",
    become_a_partner: "Staňte se partnerem",
    products: "Mé produkty",
    certificates: "Certifikáty",
    knowledge: "Znalostní báze",
    practices: "Dokončené praxe",
    dictionary: "Slovník",
    calendar: "Kalendář akcí",
    news: "Novinky",
    history: "Historie nákupů",
    localization: "Jazyk",
    settings: "Nastavení",
    exit: "Odhlásit",
    projects: "Mé projekty",
    "expert.profile.expert_organization_cabinet": "Kabinet organizace",
    "expert.profile.expert_producer_cabinet": "Kabinet producenta",
    "expert.profile.expert_cabinet": "Kabinet experta",
    "expert.profile.student_cabinet": "Kabinet studenta",
    "expert.profile.create_cabinet": "Vytvořit účet",
    subaccounts: "Podúčty",
    school: "Otevřete si vlastní školu",
    dashboard: "Panel",
    student_dashboard: "Panel",
    logout: "Odhlásit se z účtu",
    auth_type_title: "Vítejte!",
    auth_type_btn_1: "Nastavit heslo",
    auth_type_btn_2: "Zachovat aktuální metodu ověřování",
    subscribe: "Přihlásit odběr"
}
