export default {
    account_restored: "Konto zostało przywrócone",
    restore_account: "Przywróć konto",
    authorization_platform: "Zaloguj się lub utwórz konto",
    on_your: "Na Twój",
    email: "adres e-mail",
    code_has_been_sent: "został wysłany kod potwierdzający",
    enter_code: "Wprowadź kod",
    code_is_valid: "Kod jest ważny",
    minutes: "minut",
    request_new_code: "Będziesz mógł ponownie poprosić o kod za",
    sec: "sek",
    send_again: "Wyślij kod ponownie",
    back: "Wstecz",
    next: "Dalej",
    enter_password: "Wprowadź hasło",
    repeat_password: "Powtórz hasło",
    forgot_password: "Zapomniałeś hasła?",
    restore_access: "Przywróć dostęp",
    enter_username: "Wprowadź adres e-mail lub numer telefonu",
    enter_email: "Wprowadź adres e-mail",
    create_new_password: "Wymyśl nowe hasło",
    select_language: "Wybierz język",
    select_currency: "Wybierz walutę",
    language: "Język",
    currency: "Waluta",
    or: "lub",
    enter: "Zaloguj się",
    close: "Zamknij",
    fields_filled: "Pola wypełnione!",
    fill_title: "Wypełnij pola",
    fill_descr: "Aby zakończyć rejestrację, należy wypełnić pola",
    welcome: "Witamy!",
    check_locale: " Czy dobrze rozpoznaliśmy Twój język:",
    all_ok: "Tak, wszystko się zgadza",
    no_change_language: "Nie, zmień język",
    set_password: "Ustawianie hasła",
    set_password_description: "Wymyśl hasło",
    understand_thanks: "Zrozumiałem, dziękuję!",
    prerecording: "Wstępna rejestracja",
    prerecording_success: "Zostałeś dodany do listy wstępnej rejestracji",
    congratulations: "Gratulacje!",
    back_to_menu: "Wróć do <b>Menu</b>",
    //
    partners_program: "Program partnerski",
    become_a_partner: "Zostań partnerem",
    products: "Moje produkty",
    certificates: "Certyfikaty",
    knowledge: "Baza wiedzy",
    practices: "Odbyte praktyki",
    dictionary: "Słownik",
    calendar: "Kalendarz wydarzeń",
    news: "Aktualności",
    history: "Historia zakupów",
    localization: "Język",
    settings: "Ustawienia",
    exit: "Wyjście",
    projects: "Moje projekty",
    "expert.profile.expert_organization_cabinet": "Panel organizatora",
    "expert.profile.expert_producer_cabinet": "Panel producenta",
    "expert.profile.expert_cabinet": "Panel eksperta",
    "expert.profile.student_cabinet": "Panel ucznia",
    "expert.profile.create_cabinet": "Utwórz panel",
    subaccounts: "Subkonta",
    school: "Załóż własną szkołę",
    dashboard: "Panel główny",
    student_dashboard: "Moje nauczanie",
    logout: "Wyloguj się",
    auth_type_title: "Witamy!",
    auth_type_btn_1: "Ustaw hasło",
    auth_type_btn_2: "Pozostaw obecną metodę uwierzytelniania",
    subscribe: "Subskrybuj"
}
