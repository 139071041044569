export default {
    account_restored: "ანგარიში აღდგენილია",
    restore_account: "აღადგინეთ ანგარიში",
    authorization_platform: "შედით ან დარეგისტრირდით",
    on_your: "თქვენს",
    email: "ელექტრონულ ფოსტაზე",
    code_has_been_sent: "დადასტურების კოდი გაიგზავნა",
    enter_code: "შეიყვანეთ კოდი",
    code_is_valid: "კოდი სწორია",
    minutes: "წუთი",
    request_new_code: "კოდის მოთხოვნა შესაძლებელია",
    sec: "წმ",
    send_again: "გაიგზავნოს ხელახლა",
    back: "უკან",
    next: "შემდეგი",
    enter_password: "შეიყვანეთ პაროლი",
    repeat_password: "გაიმეორეთ პაროლი",
    forgot_password: "დაგავიწყდათ პაროლი?",
    restore_access: "წვდომის აღდგენა",
    enter_username: "შეიყვანეთ თქვენი ელ.ფოსტა ან ტელეფონი",
    enter_email: "შეიყვანეთ თქვენი ელ.ფოსტა",
    create_new_password: "შექმენით ახალი პაროლი",
    select_language: "არჩიეთ ენა",
    select_currency: "არჩიეთ ვალუტა",
    language: "ენა",
    currency: "ვალუტა",
    or: "ან",
    enter: "შესვლა",
    close: "დახურვა",
    fields_filled: "ველები შევსებულია!",
    fill_title: "შივსეთ ველები.",
    fill_descr: "რეგისტრაციის დასასრულებლად აუცილებელია ველების შევსება",
    welcome: "მოგესალმებით!",
    check_locale: "მითხარით, სწორად გამოვიცანით თუ არა თქვენი ენა:",
    all_ok: "დიახ, სწორია",
    no_change_language: "არა, ენის შეცვლა",
    set_password: "პაროლის დაყენება",
    set_password_description: "აირჩიეთ პაროლი",
    understand_thanks: "გასაგებია, გმადლობთ!",
    prerecording: "წინასწარი რეგისტრაცია",
    prerecording_success: "წარმატებით დაემატეთ წინასწარი რეგისტრაციის სიაში",
    congratulations: "გილოცავთ!",
    back_to_menu: "უკან <b>მენიუში</b>",
    //
    partners_program: "აფილეატის პროგრამა",
    become_a_partner: "გახდით პარტნიორი",
    products: "ჩემი პროდუქტები",
    certificates: "სერტიფიკატები",
    knowledge: "ცოდნის ბაზა",
    practices: "დასრულებული პრაქტიკები",
    dictionary: "ლექსიკონი",
    calendar: "ღონისძიებების კალენდარი",
    news: "ახალი ამბები",
    history: "შესყიდვების ისტორია",
    localization: "ენა",
    settings: "პარამეტრები",
    exit: "გასვლა",
    projects: "ჩემი პროექტები",
    "expert.profile.expert_organization_cabinet": "ორგანიზაციის კაბინეტი",
    "expert.profile.expert_producer_cabinet": "პროდიუსერის კაბინეტი",
    "expert.profile.expert_cabinet": "ექსპერტის კაბინეტი",
    "expert.profile.student_cabinet": "სტუდენტის კაბინეტი",
    "expert.profile.create_cabinet": "შექმენით ანგარიში",
    subaccounts: "ქვეანგარები",
    school: "გახსენით თქვენი სკოლა",
    dashboard: "დაფა",
    student_dashboard: "დაფა",
    logout: "გამოსვლა თქვენს ანგარიშიდან",
    auth_type_title: "მოგესალმებით!",
    auth_type_btn_1: "პაროლის დაყენება",
    auth_type_btn_2: "მიმდინარე ავთენტიფიკაციის მეთოდის შენარჩუნება",
    subscribe: "გამოწერა"
}
