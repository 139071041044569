export default {
    account_restored: "Cont restaurat",
    restore_account: "Recuperați contul",
    authorization_platform: "Autentificați-vă sau înregistrați-vă",
    on_your: "La",
    email: "adresa de email",
    code_has_been_sent: "codul de confirmare a fost trimis",
    enter_code: "Introduceți codul",
    code_is_valid: "Codul este valid",
    minutes: "minute",
    request_new_code: "Puteți solicita codul din nou în",
    sec: "secunde",
    send_again: "Trimiteți din nou codul",
    back: "Înapoi",
    next: "Următorul",
    enter_password: "Introduceți parola",
    repeat_password: "Repetați parola",
    forgot_password: "Ați uitat parola?",
    restore_access: "Restabiliți accesul",
    enter_username: "Introduceți adresa de email sau telefonul",
    enter_email: "Introduceți adresa de email",
    create_new_password: "Creați o nouă parolă",
    select_language: "Alegeți limba",
    select_currency: "Alegeți moneda",
    language: "Limbă",
    currency: "Monedă",
    or: "sau",
    enter: "Introduceți",
    close: "Închideți",
    fields_filled: "Câmpurile sunt completate!",
    fill_title: "Completați câmpurile.",
    fill_descr: "Pentru a finaliza înregistrarea, trebuie să completați câmpurile",
    welcome: "Bine ați venit!",
    check_locale: "Spuneți-mi dacă am identificat corect limba dumneavoastră:",
    all_ok: "Da, este corect",
    no_change_language: "Nu, schimbați limba",
    set_password: "Setarea unei parole",
    set_password_description: "Alegeți o parolă",
    understand_thanks: "Am înțeles, mulțumesc!",
    prerecording: "Preînregistrare",
    prerecording_success: "Ați fost adăugat cu succes pe lista de preînregistrare",
    congratulations: "Felicitări!",
    back_to_menu: "Înapoi la <b>Meniu</b>",
    //
    partners_program: "Program afiliat",
    become_a_partner: "Deveniți partener",
    products: "Produsele mele",
    certificates: "Certificate",
    knowledge: "Baza de cunoștințe",
    practices: "Practici finalizate",
    dictionary: "Dicționar",
    calendar: "Calendar de evenimente",
    news: "Știri",
    history: "Istoricul achizițiilor",
    localization: "Limbă",
    settings: "Setări",
    exit: "Ieșire",
    projects: "Proiectele mele",
    "expert.profile.expert_organization_cabinet": "Cabinetul Organizației",
    "expert.profile.expert_producer_cabinet": "Cabinetul Producătorului",
    "expert.profile.expert_cabinet": "Cabinetul Expertului",
    "expert.profile.student_cabinet": "Cabinetul Studentului",
    "expert.profile.create_cabinet": "Creați un cont",
    subaccounts: "Subconturi",
    school: "Deschideți propria școală",
    dashboard: "Tablou de bord",
    student_dashboard: "Tablou de bord",
    logout: "Deconectați-vă din cont",
    auth_type_title: "Bine ați venit!",
    auth_type_btn_1: "Setați o parolă",
    auth_type_btn_2: "Păstrați metoda actuală de autentificare",
    subscribe: "Abonați-vă"
}
