import ru from "./ru"
import en from "./en"
import uk from "./uk"
import it from "./it"
import es from "./es"
import de from "./de"
import fr from "./fr"
import pl from "./pl"
import lv from "./lv"
import pt from "./pt"
import cs from "./cs"
import ka from "./ka"
import ro from "./ro"
import el from "./el"
import zh from "./zh"

export default {
    pl,
    fr,
    de,
    ru,
    en,
    uk,
    it,
    es,
    lv,
    pt,
    cs,
    ka,
    ro,
    el,
    zh
}
