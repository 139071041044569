export default {
    account_restored: "Ο λογαριασμός αποκαταστάθηκε",
    restore_account: "Ανάκτηση λογαριασμού",
    authorization_platform: "Σύνδεση ή εγγραφή",
    on_your: "Στη",
    email: "διεύθυνση email",
    code_has_been_sent: "ο κωδικός επιβεβαίωσης έχει σταλεί",
    enter_code: "Εισαγάγετε τον κωδικό",
    code_is_valid: "Ο κωδικός είναι έγκυρος",
    minutes: "λεπτά",
    request_new_code: "Μπορείτε να ζητήσετε ξανά τον κωδικό σε",
    sec: "δευτ.",
    send_again: "Αποστολή κωδικού ξανά",
    back: "Πίσω",
    next: "Επόμενο",
    enter_password: "Εισαγάγετε τον κωδικό πρόσβασης",
    repeat_password: "Επαναλάβετε τον κωδικό πρόσβασης",
    forgot_password: "Ξεχάσατε τον κωδικό σας;",
    restore_access: "Αποκατάσταση πρόσβασης",
    enter_username: "Εισαγάγετε το email ή τον αριθμό τηλεφώνου σας",
    enter_email: "Εισαγάγετε το email σας",
    create_new_password: "Δημιουργήστε νέο κωδικό πρόσβασης",
    select_language: "Επιλέξτε γλώσσα",
    select_currency: "Επιλέξτε νόμισμα",
    language: "Γλώσσα",
    currency: "Νόμισμα",
    or: "ή",
    enter: "Εισαγωγή",
    close: "Κλείσιμο",
    fields_filled: "Τα πεδία συμπληρώθηκαν!",
    fill_title: "Συμπληρώστε τα πεδία.",
    fill_descr: "Για να ολοκληρώσετε την εγγραφή, πρέπει να συμπληρώσετε τα πεδία",
    welcome: "Καλώς ήρθατε!",
    check_locale: "Πείτε μας αν έχουμε αναγνωρίσει σωστά τη γλώσσα σας:",
    all_ok: "Ναι, σωστά",
    no_change_language: "Όχι, αλλάξτε τη γλώσσα",
    set_password: "Ορισμός κωδικού πρόσβασης",
    set_password_description: "Δημιουργήστε έναν κωδικό πρόσβασης",
    understand_thanks: "Κατάλαβα, ευχαριστώ!",
    prerecording: "Προεγγραφή",
    prerecording_success: "Προστεθήκατε επιτυχώς στη λίστα προεγγραφής",
    congratulations: "Συγχαρητήρια!",
    back_to_menu: "Πίσω στο <b>Μενού</b>",
    //
    partners_program: "Συνεργατικό πρόγραμμα",
    become_a_partner: "Γίνετε συνεργάτης",
    products: "Τα προϊόντα μου",
    certificates: "Πιστοποιητικά",
    knowledge: "Βάση γνώσεων",
    practices: "Ολοκληρωμένες πρακτικές",
    dictionary: "Λεξικό",
    calendar: "Ημερολόγιο εκδηλώσεων",
    news: "Νέα",
    history: "Ιστορικό αγορών",
    localization: "Γλώσσα",
    settings: "Ρυθμίσεις",
    exit: "Έξοδος",
    projects: "Τα έργα μου",
    "expert.profile.expert_organization_cabinet": "Οργανωτικό Γραφείο",
    "expert.profile.expert_producer_cabinet": "Γραφείο Παραγωγού",
    "expert.profile.expert_cabinet": "Γραφείο Ειδικού",
    "expert.profile.student_cabinet": "Γραφείο Μαθητή",
    "expert.profile.create_cabinet": "Δημιουργία λογαριασμού",
    subaccounts: "Υπολογαριασμοί",
    school: "Ανοίξτε το δικό σας σχολείο",
    dashboard: "Πίνακας ελέγχου",
    student_dashboard: "Πίνακας ελέγχου",
    logout: "Αποσύνδεση από το λογαριασμό σας",
    auth_type_title: "Καλώς ήρθατε!",
    auth_type_btn_1: "Ορισμός κωδικού πρόσβασης",
    auth_type_btn_2: "Διατήρηση της τρέχουσας μεθόδου σύνδεσης",
    subscribe: "Εγγραφή"
}
