export default {
    account_restored: "Cuenta restaurada",
    restore_account: "Recuperar cuenta",
    authorization_platform: "Iniciar sesión o registrarse",
    on_your: "A tu",
    email: "dirección de correo electrónico",
    code_has_been_sent: "se ha enviado el código de confirmación",
    enter_code: "Ingrese el código",
    code_is_valid: "El código es válido",
    minutes: "minutos",
    request_new_code: "Puedes solicitar el código nuevamente en",
    sec: "segundos",
    send_again: "Enviar código nuevamente",
    back: "Atrás",
    next: "Siguiente",
    enter_password: "Ingrese la contraseña",
    repeat_password: "Repetir contraseña",
    forgot_password: "¿Olvidaste tu contraseña?",
    restore_access: "Restaurar acceso",
    enter_username: "Ingrese su dirección de correo electrónico o teléfono",
    enter_email: "Ingrese su dirección de correo electrónico",
    create_new_password: "Cree una nueva contraseña",
    select_language: "Elija el idioma",
    select_currency: "Elija la moneda",
    language: "Idioma",
    currency: "Moneda",
    or: "o",
    enter: "Entrar",
    close: "Cerrar",
    fields_filled: "¡Campos completados!",
    fill_title: "Complete los campos.",
    fill_descr: "Para completar el registro, deben llenarse los campos",
    welcome: "¡Bienvenido!",
    check_locale: "Dinos si hemos identificado correctamente tu idioma:",
    all_ok: "Sí, es correcto",
    no_change_language: "No, cambiar el idioma",
    set_password: "Estableciendo una contraseña",
    set_password_description: "Elige una contraseña",
    understand_thanks: "¡Entendido, gracias!",
    prerecording: "Preinscripción",
    prerecording_success: "Has sido agregado con éxito a la lista de preinscripción",
    congratulations: "¡Felicitaciones!",
    back_to_menu: "Volver al <b>Menú</b>",
    //
    partners_program: "Programa de afiliados",
    become_a_partner: "Conviértete en un socio",
    products: "Mis productos",
    certificates: "Certificados",
    knowledge: "Base de conocimientos",
    practices: "Prácticas completadas",
    dictionary: "Diccionario",
    calendar: "Calendario de eventos",
    news: "Noticias",
    history: "Historial de compras",
    localization: "Idioma",
    settings: "Configuraciones",
    exit: "Salir",
    projects: "Mis proyectos",
    "expert.profile.expert_organization_cabinet": "Gabinete de la Organización",
    "expert.profile.expert_producer_cabinet": "Gabinete del Productor",
    "expert.profile.expert_cabinet": "Gabinete del Experto",
    "expert.profile.student_cabinet": "Gabinete del Estudiante",
    "expert.profile.create_cabinet": "Crear una cuenta",
    subaccounts: "Subcuentas",
    school: "Abre tu propia escuela",
    dashboard: "Panel de control",
    logout: "Cerrar sesión de tu cuenta",
    auth_type_title: "¡Bienvenido!",
    auth_type_btn_1: "Establecer una contraseña",
    auth_type_btn_2: "Mantener el método de autenticación actual",
    subscribe: "Suscribirse"
}
