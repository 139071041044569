export default {
    account_restored: "Conta restaurada",
    restore_account: "Restaurar conta",
    authorization_platform: "Entrar ou criar conta",
    on_your: "No seu",
    email: "endereço de e-mail",
    code_has_been_sent: "um código de verificação foi enviado",
    enter_code: "Digite o código",
    code_is_valid: "O código é válido",
    minutes: "minutos",
    request_new_code: "Você poderá solicitar um novo código em",
    sec: "seg",
    send_again: "Reenviar código",
    back: "Voltar",
    next: "Avançar",
    enter_password: "Digite a senha",
    repeat_password: "Digite a senha novamente",
    forgot_password: "Esqueceu a senha?",
    restore_access: "Restaurar acesso",
    enter_username: "Digite o e-mail ou telefone",
    enter_email: "Digite o endereço de e-mail",
    create_new_password: "Crie uma nova senha",
    select_language: "Selecione o idioma",
    select_currency: "Selecione a moeda",
    language: "Idioma",
    currency: "Moeda",
    or: "ou",
    enter: "Entrar",
    close: "Fechar",
    fields_filled: "Campos preenchidos!",
    fill_title: "Preencha os campos",
    fill_descr: "Para concluir o cadastro, você precisa preencher os campos",
    welcome: "Bem-vindo!",
    check_locale: " Você poderia confirmar se identificamos corretamente o idioma?",
    all_ok: "Sim, está correto",
    no_change_language: "Não, alterar idioma",
    set_password: "Definir senha",
    set_password_description: "Crie uma senha",
    understand_thanks: "Entendido, obrigado!",
    prerecording: "Pré-inscrição",
    prerecording_success: "Você foi adicionado à lista de pré-inscrição",
    congratulations: "Parabéns!",
    back_to_menu: "Voltar ao <b>Menu</b>",
    //
    partners_program: "Programa de parceiros",
    become_a_partner: "Torne-se um parceiro",
    products: "Meus produtos",
    certificates: "Certificados",
    knowledge: "Base de conhecimento",
    practices: "Práticas concluídas",
    dictionary: "Dicionário",
    calendar: "Calendário de eventos",
    news: "Notícias",
    history: "Histórico de compras",
    localization: "Idioma",
    settings: "Configurações",
    exit: "Sair",
    projects: "Meus projetos",
    "expert.profile.expert_organization_cabinet": "Painel do organizador",
    "expert.profile.expert_producer_cabinet": "Painel do produtor",
    "expert.profile.expert_cabinet": "Painel do especialista",
    "expert.profile.student_cabinet": "Painel do aluno",
    "expert.profile.create_cabinet": "Criar painel",
    subaccounts: "Subcontas",
    school: "Abrir sua escola",
    dashboard: "Painel",
    student_dashboard: "Meu aprendizado",
    logout: "Sair da conta",
    auth_type_title: "Bem-vindo!",
    auth_type_btn_1: "Definir senha",
    auth_type_btn_2: "Manter o método de autenticação atual",
    subscribe: "Assinar"
}
